<script setup lang="ts">
import { PropType, onMounted, ref, onUnmounted, toRaw, watch } from 'vue';
import DownArrorIcon from '../icons/DownArrowIcon.vue';

type Option = {
  label: string;
  value: unknown;
};

const props = defineProps({
  placeHolder: {
    type: String,
    required: true,
  },
  options: {
    type: Array as PropType<Option[]>,
    required: true,
  },
  useBackground: {
    type: Boolean,
  },
  label: {
    type: String,
    default: undefined
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  labelStyles: {
    type: String,
    default: ''
  },
  modelValue: {
    type: [Object, String, Number, Array],
    default: null,
  },
});

const optionHeight = 40;
const emit = defineEmits(['update:modelValue', 'optionClicked']);
const dropdownOpen = ref(false);
const root = ref<HTMLDivElement>();
const selectRef = ref<HTMLDivElement>();
const distanceFromTop = ref(0);
const width = ref(0);
const distanceFromLeft = ref(0);

const positionDropdown = () => {
  const rect = root.value?.getBoundingClientRect();
  const dropdownHeight = props.options.length * optionHeight;

  distanceFromTop.value = (rect?.top ?? 0) + (rect?.height ?? 0);
  distanceFromLeft.value = rect?.left ?? 0;
  width.value = rect?.width ?? 0;

  if (window.innerHeight < (rect?.top ?? 0) + (rect?.height ?? 0) + dropdownHeight)
    distanceFromTop.value = distanceFromTop.value - 160 - (rect?.height ?? 0);
};

const detectClickOutside = async (event: MouseEvent) => {
  if (!root.value?.contains(event.target as Node)) {
    dropdownOpen.value = false;
  }
};

const handleOnClickOption = (option: Option | null) => {
  emit('optionClicked', option?.value);
  emit('update:modelValue', option?.value);
  dropdownOpen.value = false;
};

const handleOnClickSelect = () => {
  positionDropdown();
  dropdownOpen.value = !dropdownOpen.value;
};

watch(dropdownOpen, () => {
  if (dropdownOpen.value)
    window.addEventListener('resize', positionDropdown);
  else
    window.removeEventListener('resize', positionDropdown);
});

onMounted(() => {
  document.addEventListener('click', detectClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', detectClickOutside);
});

const getSelectedOptionLabel = (modelValue: unknown) =>
  props.options.find((option) => toRaw(option.value) == toRaw(modelValue))?.label;
</script>

<template>
  <div ref="root">
    <div
      v-if="props.label"
      class="md:p-0 p-0"
      :class="props.labelStyles"
    >
      {{ props.label }}
    </div>
    <!-- The select is only there to make the div width correct -->
    <div class="h-0 px-5 z-0">
      <select
        class="h-0"
        :disabled="props.disabled"
      >
        <option>{{ props.placeHolder }}</option>
        <option
          v-for="option in props.options"
          :key="option.label"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <!-- Dropdown -->
    <div
      ref="selectRef"
      class="relative text-left"
    >
      <div
        :class="useBackground ? 'bg-gray-700' : 'border border-gray-600'"
        class="px-4 py-2 rounded-md cursor-pointer relative select"
        @click="() => !props.disabled && handleOnClickSelect()"
      >
        <div class="w-full h-full bg-transparent absolute top-0 left-0 z-2 select-none" />
        <div
          v-if="!props.modelValue && props.modelValue != 0"
          class="text-gray-400"
        >
          {{ placeHolder }}
        </div>
        <div
          v-else
          class="text-white"
        >
          {{ getSelectedOptionLabel(props.modelValue) }}
        </div>
        <div class="absolute right-4 bottom-0 top-0 flex items-center w-2">
          <down-arror-icon />
        </div>
      </div>
      <teleport to="html">
        <transition
          enter-active-class="transition-all ease-out"
          leave-active-class="transition-all ease-in"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="dropdownOpen"
            class="fixed top-0 left-0 bottom-0 right-0 z-50"
          >
            <div
              :class="`
                shadow-xl
                absolute
                text-white
                bg-gray-700
                w-full
                mt-2
                rounded-md
                z-50
                overflow-auto
                scroll-bar-thin
                max-h-60
              `"
              :style="{ top: `${distanceFromTop}px`, width: `${width}px`, left: `${distanceFromLeft}px` }"
            >
              <div
                v-for="option in props.options"
                :key="option.label"
                class="w-full overflow-hidden truncate p-2 hover:bg-gray-600 cursor-pointer select-none rounded-md"
                @click="() => handleOnClickOption(option)"
              >
                {{ option.label }}
              </div>
            </div>
          </div>
        </transition>
      </teleport>
    </div>
  </div>
</template>
<style scoped>
th {
  background-color: #1e1e1e;
  position: sticky;
  top: 0;
}
</style>
